import React from "react";
import "./Spa.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import FAB from "../FloatingCTA/FloatingCTA";


const Amenities = () => {
  return (
    <div className="amenities-main">
      <Header />
      <FAB/>
      <div className="amenities-hero-bg">
        <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Amenities/amenities-hero.png" alt="amenities background" />
        <h1>Rejuvenate Your Body, Mind and Soul</h1>
      </div>
      <div className="spa-section">
        <div className="spa-header">
          <p className="amenity-title">spa</p>
          <h1 className="amenity-heading">Cynara</h1>
          <p className="amenity-desc">
            Melt away stress and indulge in pure bliss at our world-class spa.
            From rejuvenating massages and facials to aromatherapy and
            hydrotherapy treatments, our expert therapists will tailor a journey
            to your unique needs.
          </p>
          <div className="spa-hero-img" data-aos="fade-up" data-aos-duration="1000">
            <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Amenities/spa-hero.png" alt="amenities background" />
          </div>
          <div className="spa-offerings">
            <div className="promise" data-aos="fade-up" data-aos-duration="1000" >
              <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Amenities/Therapists.svg" alt="spa offerings" className="type" />
              <p>Certified Therapists</p>
            </div>

            <div className="promise" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
              <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Amenities/hygiene.svg" alt="spa offerings" className="type" />

              <p>Commitment to Hygiene</p>
            </div>

            <div className="promise" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
              <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Amenities/quality-products.svg" alt="spa offerings" className="type" />

              <p>Quality Products</p>
            </div>

            <div className="promise" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
              <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Amenities/researched-therapies.svg" alt="spa offerings" className="type" />

              <p>Researched Therapies</p>
            </div>
          </div>
          <div className="spa-features">
            <div className="feature" data-aos="fade-up" data-aos-duration="1000" >
              <div className="feature-img-1" >
                <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Amenities/feature01.png" alt="amenities background" />
              </div>
              <p>Body Massages and facials</p>
            </div>

            <div className="feature" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
              <div className="feature-img-2">
                <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Amenities/feature02.png" alt="amenities background" />
              </div>
              <p>Salt & steam room</p>
            </div>

            <div className="feature" data-aos="fade-up" data-aos-duration="1000" >
              <div className="feature-img-2">
                <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Amenities/feature03.png" alt="amenities background" />
              </div>
              <p>Body Scrubs & Wraps</p>
            </div>

            <div className="feature" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
              <div className="feature-img-1">
                <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Amenities/feature04.png" alt="amenities background" />
              </div>
              <p>Medicure & Pedicure</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Amenities;
