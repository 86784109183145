import React, { useState, useEffect } from "react";
import "./FloatingCTA.scss";


const FloatingCTA = () => {

  return (
    <div className="main-fab-conatiner">
      <a  href="tel: 080 22222224" target="_blank" className="call-icon" >
        <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/FAB/Call.svg" alt="Call" />
      </a>
      <a href="https://wa.me/919591375999" target="_blank" className="whatsapp-icon">
        <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/FAB/WhatsApp.svg" alt="What's App" />
      </a>
    </div>
  );
};
export default FloatingCTA;
