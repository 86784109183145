

export const pData = [
    {
      title: "Day Out Package",
      description: "Surrounded by scenic beauty and attractive tourist attractions we make it convenient for you to visit all the beautiful places",
      price: "1499 + GST",
      sports:"Food ◆ Indoor Games ◆ Outdoor Games ◆ Adventure Games(Charged separately)",
      img:"https://sporticoresorts.s3.ap-south-1.amazonaws.com/Packages/day_out_package.png",
      link: "",
    },
    
  ];


  export const corporatePackage = [
    {
      title: "Skills & Adventure Package 1",
      description: "Boost team spirit with engaging activities that enhance teamwork and provide unforgettable fun.",
      price: "1800",
      sports:"◆ Pipeline ◆ Magic Turtle ◆ Archery Tag ◆ Bubble Soccer  ◆ Human Foosball",
      img: "https://sporticoresorts.s3.ap-south-1.amazonaws.com/Packages/cp-01.png",
      link: "",
    },
    {
      title: "Skills & Adventure Package 2",
      description: "Strengthen team bonds through dynamic activities designed for collaboration and memorable experiences.",
      price: "1800",
      sports:"◆ Pipeline ◆ Magic Turtle ◆ Pyramid Builder ◆ Archery Tag ◆ Human Foosball",
      img:"https://sporticoresorts.s3.ap-south-1.amazonaws.com/Packages/cp-02.png",
      link: "",
    },
    {
      title: "Skills & Adventure Package 3",
      description: "Elevate teamwork with unique challenges and engaging activities for a truly unforgettable experience.",
      price: "1650",
      sports:"◆ Pipeline ◆ Magic Turtle ◆ Pyramid Builder ◆ Key Punch",
      img:"https://sporticoresorts.s3.ap-south-1.amazonaws.com/Packages/cp-03.png",
      link: "",
    },
    {
      title: "Sportico Brave Heart",
      description: "Challenge your limits with action-packed activities, perfect for thrill-seekers and adventure lovers.",
      price: "3600",
      sports:"◆ High Rope Course ◆ Zipline ◆ Climbing Wall ◆ Kayaking ◆ Free Fall",
      img:"https://sporticoresorts.s3.ap-south-1.amazonaws.com/Packages/brave-heart.png",
      link: "",
    },
    {
      title: "Sportico Team Player",
      description: "Enhance team spirit and camaraderie with engaging and fun group activities.",
      price: "1800",
      sports:"◆ Archery Tag ◆ Bubble Soccer ◆ Human Foosball ◆ Kayaking ◆ MeltDown",
      img:"https://sporticoresorts.s3.ap-south-1.amazonaws.com/Packages/team-player.png",
      link: "",
    },
    {
      title: "Sportico Warrior",
      description: "Unleash your competitive edge with high-energy team challenges and strategic games.",
      price: "2100",
      sports:"◆ Paintball ◆ Archery Tag ◆ Bubble Soccer ◆ Human Foosball ",
      img:"https://sporticoresorts.s3.ap-south-1.amazonaws.com/Packages/warrior.png",
      link: "",
    },
    {
      title: "Sportico Racer",
      description: "Fuel your need for speed and excitement with racing and adventurous activities.",
      price: "3100",
      sports:"◆ Dirt Kart ◆ Kayaking ◆ Paintball ◆ Climbing Wall ",
      img:"https://sporticoresorts.s3.ap-south-1.amazonaws.com/Packages/racer.png",
      link: "",
    },
    {
      title: "Sportico Fly High",
      description: "Soar to new heights with thrilling aerial and climbing experiences.",
      price: "3500",
      sports:"◆ High Rope Course ◆ Zipline ◆ Climbing Wall ◆ Free Fall ",
      img:"https://sporticoresorts.s3.ap-south-1.amazonaws.com/Packages/fly-high.png",
      link: "",
    },
    {
      title: "Sportico Lone Ranger",
      description: "Conquer new challenges solo, perfect for individual adventure seekers looking for a thrilling escape.",
      price: "1850",
      sports:"◆ Climbing Wall ◆ High Rope Course ◆ Kayaking",
      img:"https://sporticoresorts.s3.ap-south-1.amazonaws.com/Packages/lone-ranger.png",
      link: "",
    },
  ];