import React from "react";
import "./Events.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import FAB from "../FloatingCTA/FloatingCTA";


const Events = () => {
  return (
    <div className="events-main">
      <Header />
      <FAB/>
      <div className="events-hero-bg">
        <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/events-hero-bg.png" alt="events background" />
        <h1>Best Destination Wedding Venue In Bangalore</h1>
      </div>
      <div className="events-section-two">
        <p>
          We specialise in creating remarkable experiences for weddings, social gatherings, and corporate events. Our adaptable venues and committed staff ensure that each event is memorable. Experience unmatched luxury and elegance at Sportico, the best wedding destination in Bangalore.
        </p>
        <div className="event-types">
            <div className="facility-name" data-aos="fade-up" data-aos-duration="1000" >
              <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/Rooms.svg" alt="spa offerings" className="type" />
              <p>140+ Rooms</p>
            </div>

            <div className="facility-name" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
              <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/Events.svg" alt="spa offerings" className="type" />

              <p>Event Planners</p>
            </div>

            <div className="facility-name" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
              <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/Culinary.svg" alt="spa offerings" className="type" />

              <p>Culinary Services</p>
            </div>

            <div className="facility-name" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
              <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/Parking.svg" alt="spa offerings" className="type" />

              <p>Huge Parking Space</p>
            </div>
          </div>
      </div>
      <div className="events-section-three">
        <div className="banquets-main">
          <div className="banquet-item">
            <h1>Elegance</h1>
            <p>
              A refined space designed for sophistication and timeless
              celebrations. From weddings to corporate events, experience the
              perfect blend of style with top-class modern furniture and a great
              ambience.
            </p>
            <p> Closed Banquet Hall</p>
            <p className="banquet-capacity">Max capacity 500 people</p>
            <img className="venue-img" src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/banquet_1.png" alt="Corporate venue" data-aos="fade-up" data-aos-duration="1000" />
            <divider className="banquet-divider"></divider>
            <h1>Aura</h1>
            <p>
              Step into the enchanting atmosphere of Aura Open Banquet Hall at
              Sportico. Designed to captivate, this space radiates charm,
              offering the perfect setting for your special occasions and
              celebrations.
            </p>
            <p> Open Banquet Hall</p>
            <p className="banquet-capacity">Max capacity 1000 people</p>
            <img className="venue-img" src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/banquet_2.png" alt="Corporate venue" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" />
            <divider className="banquet-divider"></divider>
            <h1>The Green Banquet</h1>
            <p>
              Experience the spacious Green Banquet, embraced by lush greenery
              and surrounded by the resort's natural beauty. An ideal venue for
              grand celebrations and unforgettable events.
            </p>
            <p> Open Banquet Hall</p>
            <p className="banquet-capacity">Max capacity 2000 people</p>
            <img className="venue-img" src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/banquet_3.png" alt="Corporate venue" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800" />
          </div>
        </div>
      </div>

      <div className="events-section-four">
        <img
          className="events-divider"
          src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/events-divider.svg"
          alt="Diver background"
        />
        <h1>Corporate Venues</h1>
        <p>
          We redefine corporate events, offering state-of-the-art facilities,
          versatile spaces, and a dedicated team to elevate your business
          gatherings. From conferences to team-building retreats, our resort
          provides a perfect blend of professionalism and relaxation. Experience
          corporate events like never before, where every detail is crafted to
          inspire success and encourage connections.
        </p>
        <div className="corporate-venues-main">
          <div className="venue-item" data-aos="fade-up" data-aos-duration="1000" >
            <img className="venue-img" src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/opal.png" alt="Corporate venue" />
            <p>opal</p>
          </div>
          <div className="venue-item" data-aos="fade-up" data-aos-duration="1500" >
            <img className="venue-img" src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/citrine.png" alt="Corporate venue" />
            <p>citrine</p>
          </div>
          <div className="venue-item" data-aos="fade-up" data-aos-duration="2000" >
            <img className="venue-img" src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/onyx.png" alt="Corporate venue" />
            <p>onyx</p>
          </div>
        </div>
      </div>

      <div className="events-section-five">
        <h1 className="text-6xl">Exquisite Moments</h1>

        <div className="evt-img-row">
          <div className="evt-img e-img-1" data-aos="fade-up" data-aos-duration="1000">
            <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/wedding.png" alt="Weddings" />
            <p className="">Weddings</p>
          </div>
          <div className="evt-img" data-aos="fade-up" data-aos-duration="2000">
            <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/baby-showers.png" alt="Baby Showers" />
            <p className="">Baby Showers</p>
          </div>
        </div>
        <div className="evt-img-row e-row-2" >
          <div className="evt-img" data-aos="fade-up" data-aos-duration="1000">
            <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/birthday-parties.png" alt="Birthday Parties" />
            <p className="">Birthday Parties</p>
          </div>
          <div className="evt-img" data-aos="fade-up" data-aos-duration="2000">
            <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/conference.png" alt="Conference Meetings" />
            <p className="">Conference Meetings</p>
          </div>
          <div className="evt-img" data-aos="fade-up" data-aos-duration="3000">
            <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/dj-parties.png" alt="DJ Nights" />
            <p className="">DJ Nights</p>
          </div>
        </div>
        <div className="evt-img-row">
          <div className="evt-img" data-aos="fade-up" data-aos-duration="1000">
            <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/together.png" alt="Celebrate Together" />
            <p className="">Celebrate Together</p>
          </div>
          <div className="evt-img" data-aos="fade-up" data-aos-duration="2000">
            <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/mehandi.png" alt="Mehandi" />
            <p className="">Mehandi</p>
          </div>
        </div>

        <div className="evt-img-row e-row-4">
          <div className="evt-img" data-aos="fade-up" data-aos-duration="1000">
            <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/pool-party.png" alt="Pool Parties" />
            <p className="">Pool Parties</p>
          </div>
          <div className="evt-img" data-aos="fade-up" data-aos-duration="2000">
            <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/pre-wedding.png" alt="Pre Wedding" />
            <p className="">Pre Wedding</p>
          </div>
          <div className="evt-img" data-aos="fade-up" data-aos-duration="3000">
            <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/launch-events.png" alt="Launch Events" />
            <p className="">Launch Events</p>
          </div>
          <div className="evt-img" data-aos="fade-up" data-aos-duration="4000">
            <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Events/team-building.png" alt="Team Building" />
            <p className="">Team Building</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Events;
