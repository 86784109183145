import React from 'react';
import "./Adventure.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import FAB from "../FloatingCTA/FloatingCTA";
import { MdArrowRightAlt } from "react-icons/md";

const Adventure = () => {
  return (
    <div className="adv-main-container">
      <Header />
      <FAB/>
      <div className="adv-hero-img">
        <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Adventures/adventure-bg.png" alt="adventure background" />
        <div className="adv-hero-content">
          <h1>Your next adventure starts here</h1>
          <a href="/packages" target="_blank" className="package-cta">
            View packages
          </a>
        </div>
      </div>
      <div className="adv-section-two">
        <p>
          Get ready for an adrenaline rush at Sportico, Bangalore's top spot for
          outdoor adventures! Located in Malur, near Whitefield, our adventure
          sports offers everything from kayaking to zip-lining, dirt karting to
          team challenges. Join us for an unforgettable journey of excitement
          and exploration in Sportico Luxury resort Bangalore!
        </p>
      </div>
      <div className="adv-games-main">
        <div className='adv-title'>
          <h1>Adventure Sports</h1>
          {/* <span>Opening soon</span> */}
        </div>

        <div className="adv-games-row1"  >
          <div className="adv-games-content">
            <div className="adv-game sport1" data-aos="fade-up" data-aos-duration="1000">
              <p> Dirt Kart</p>
              <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Adventure+Images/Dirt_Kart.png" alt="Dirt_kart" />
            </div>
          </div>
          <div className="adv-games-content ">
            <div className="adv-game" data-aos="fade-up" data-aos-duration="1500">
              <p>Wall Climbing</p>
              <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Adventure+Images/Wall_Climbing.png" alt="Wall_Climbing" />
            </div>
            <div className="adv-game" data-aos="fade-up" data-aos-duration="1000">
              <p>Freefall</p>
              <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Adventure+Images/Freefall.png" alt="Freefall" />
            </div>
          </div>
          <div className="adv-games-content">
            <div className="adv-game sport1" data-aos="fade-up" data-aos-duration="1500">
              <p>Paint Ball</p>
              <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Adventure+Images/Paint_Ball.png" alt="Paint_Ball" />
            </div>
            <div className="adv-last-row">
              <div className="adv-game" data-aos="fade-up" data-aos-duration="1000">
                <p>Zipline</p>
                <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Adventure+Images/Zipline.png" alt="Zipline" />
              </div>
              <div className="adv-game" data-aos="fade-up" data-aos-duration="1000">
                <p>High Rope Course</p>
                <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Adventure+Images/High_Rope_Course.png" alt="High_Rope_Course" />
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="adv-section-three">
        <div className="games">
          <div
            className="adv-game-img"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Adventures/carrom.png" alt="event image" />
          </div>
          <div
            className="adv-game-img"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Adventures/billiards.png" alt="event image" />
          </div>
          <div className="adv-game-content">
            <div className='adv-title'>

              <h1>Indoor Games</h1>
            </div>
            <ul>
              <li>
                {" "}
                <MdArrowRightAlt />
                Billiards
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Air Hockey
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Caroms
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Fuse Ball
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Table Tennis
              </li>
            </ul>
          </div>
        </div>
        <div className="games outdoor-main">
          <div
            className="adv-game-img"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Adventures/cricket.png" alt="event image" />
          </div>
          <div className="adv-game-content">
            <div className='adv-title'>

              <h1>Outdoor Games</h1>
            </div>
            <ul>
              <li>
                {" "}
                <MdArrowRightAlt />
                Cricket
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Football
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Volleyball
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Badminton
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Bubble Soccer
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Human Fuse Ball
              </li>
            </ul>
          </div>
          <div
            className="adv-game-img"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <img src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Adventures/badmintion.png" alt="event image" />
          </div>
        </div>

        <div className="games">
          <div className="adv-game-content">
            <div className='adv-title'>
              <h1>Aqua Games</h1>
              {/* <span>Opening soon</span> */}
            </div>
            <ul>
              <li>
                {" "}
                <MdArrowRightAlt />
                Kayaking
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Watercycles
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Pool Throw Ball
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Pool Volley Ball
              </li>
            </ul>
          </div>
          <div className="adv-game-img">
            <img
              src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Adventures/pool-ball.png"
              alt="event image"
              data-aos="fade-up"
              data-aos-duration="1000"
            />
          </div>
          <div className="adv-game-img">
            <img
              src="https://sporticoresorts.s3.ap-south-1.amazonaws.com/Adventures/kayaking-img.png"
              alt="event image"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Adventure;
