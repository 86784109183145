import React from "react";
import "./ModalContent.scss";
import header_logo from "../Assets/sportico-logo.svg";
import { NavLink } from "react-router-dom";
import {
  FaXTwitter,
  FaLinkedinIn,
  FaYoutube,
  FaFacebookF,
} from "react-icons/fa6";
import { AiOutlineInstagram } from "react-icons/ai";

const ModalContent = () => {
  return (
    <div className="modal-main">
      <div className="modal-menu">
        <ul className="nav-links">
        <li>
            <NavLink to="/" activeclassname="active" className="menu-links">
              {/* <MdArrowRightAlt className="prefix-icon"/> */}
              Home
            </NavLink>
          </li>
        <li>
            <NavLink
              to="/packages"
              activeclassname="active"
              className="menu-links"
            >
              {/* <MdArrowRightAlt className="prefix-icon" /> */}
              Packages
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/rooms"
              activeclassname="active"
              className="menu-links"
            >
              {/* <MdArrowRightAlt className="prefix-icon" /> */}
              Accomodation
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/amenities"
              activeclassname="active"
              className="menu-links"
            >
              {/* <MdArrowRightAlt className="prefix-icon" /> */}
              Experiences
            </NavLink>
          </li>
        

          <li>
            <NavLink
              to="/adventure"
              activeclassname="active"
              className="menu-links"
            >
              {/* <MdArrowRightAlt className="prefix-icon" /> */}
              Adventures
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/events"
              activeclassname="active"
              className="menu-links"
            >
              {/* <MdArrowRightAlt className="prefix-icon"/> */}
              Events
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              activeclassname="active"
              className="menu-links"
            >
              {/* <MdArrowRightAlt className="prefix-icon"/> */}
              About us
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/spa"
              activeclassname="active"
              className="menu-links"
            >
              {/* <MdArrowRightAlt className="prefix-icon"/> */}
              SPA
            </NavLink>
          </li>
        </ul>
      </div>

      <div className="modal-contact">
        <div className="menu-contact">
          <img className="" src={header_logo} alt="logo" />
          <h6>Escape, Explore, Experience</h6>
        </div>

        <div className="menu-contact">
          <h6>Luxury resort, Adventure Sports & Spa</h6>
          <p>
            {" "}
            Near Nisarga vision city, Sithanayakalli, Malure Taluk, Kolar
            District, Karnataka - 563130
          </p>
        </div>

        <div className="menu-contact">
          <h6>Contact us</h6>
          <p>+91 9591374999</p>
          <p>enquiry@sporticoresorts.com</p>
          <div className="footer-links">
            <ul>
              <li>
                <a
                  href="https://instagram.com/sportico_bangalore"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiOutlineInstagram className="footer-social-icons" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/p/Sportico-Bangalore-61553086478221/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF className="footer-social-icons" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/sportico_blr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaXTwitter className="footer-social-icons" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/sportico-resorts/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn className="footer-social-icons" />
                </a>
              </li>
              <li>
                <a
                  href="https://youtube.com/@SporticoResorts"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube className="footer-social-icons" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalContent;
