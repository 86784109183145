import React from "react";
import "./HomeGallery.scss";
import img1 from "../Assets/g-img-01.png";
import img2 from "../Assets/g-img-02.png";
import img3 from "../Assets/g-img-03.png";
import img4 from "../Assets/g-img-04.png";
import img5 from "../Assets/g-img-05.png";
import img6 from "../Assets/g-img-06.png";
import img7 from "../Assets/g-img-07.png";
import img8 from "../Assets/g-img-08.png";

import gallery_divider from "../Assets/Divider.svg";  

const HomeGallery = () => {
  const altText = "Images of the resort";
  const row1 = [img1, img2, img3, img4];
  const row2 = [img5, img6, img7, img8];


  const row1Images = (count) => {
    return Array.from({ length: count }, (_, index) => (
      <div key={index} data-aos="fade-up" data-aos-duration="1000" >
        <img src={row1[index]} alt={altText} />
      </div>
    ));
  };
  const row2Images = (count) => {
    return Array.from({ length: count }, (_, index) => (
      <div key={index} data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
        <img src={row2[index]} alt={altText} />
      </div>
    ));
  };

  return (
    <div className="main-gallery">
      <img className="gallery-divider" src={gallery_divider} alt="Diver background" />

      <h1>Gallery</h1>
      <section>
        <div className="row row-1" >{row1Images(4)}</div>

        <div className="row row-1">{row2Images(4)}</div>
      </section>
    </div>
  );
};

export default HomeGallery;
